import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { media } from '../utils/styles/media.js';

const StyledGridList = styled.ul.withConfig({
  componentId: "sg1730__sc-kpdxj-0"
})(["display:flex;flex-wrap:wrap;justify-content:", ";list-style:none;margin-bottom:", ";margin-left:-", "rem;margin-right:-", "rem;margin-top:-", ";padding:0;"], props => props.$justifyContent, props => props.$noMargin ? '0' : tokens.space.lg, props => parseFloat(tokens.space[props.$gap || 'md']) / 2, props => parseFloat(tokens.space[props.$gap || 'md']) / 2, props => tokens.space[props.$gap || 'md']);
const getWidth = columns => {
  if (columns === 1) {
    return '100%';
  }
  if (columns === 2) {
    return '50%';
  }
  if (columns === 3) {
    return '33.333333%';
  }
  if (columns === 4) {
    return '25%';
  }
  return undefined;
};
const StyledGridListItem = styled.li.withConfig({
  componentId: "sg1730__sc-kpdxj-1"
})(["display:flex;padding:0 ", "rem;margin-top:", ";width:100%;", "{width:", ";}", "{width:", ";}", "{width:", ";}", "{width:", ";}"], props => parseFloat(tokens.space[props.$gap || 'md']) / 2, props => tokens.space[props.$gap || 'md'], media.sm, props => getWidth(props.$sm), media.md, props => getWidth(props.$md), media.lg, props => getWidth(props.$lg), media.xl, props => getWidth(props.$xl));

export { StyledGridList, StyledGridListItem };
